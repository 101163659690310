var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.category.categoryImagePath,
            _vm.category.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.category.categoryImageIsDefault &&
          _vm.checkPrivilege(_vm.hasCategoryDeleteImage())},on:{"changeValue":function($event){_vm.category.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.category.fullCode,"title":_vm.$t('Categories.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.category.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-categoryNameAr"),"errors":_vm.errors_categoryNameAr,"value":_vm.category.categoryNameAr,"title":_vm.$t('Categories.nameAr'),"imgName":'categories.svg'},on:{"changeValue":function($event){_vm.category.categoryNameAr = $event;
            _vm.$v.category.categoryNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-categoryNameEn"),"errors":_vm.errors_categoryNameEn,"value":_vm.category.categoryNameEn,"title":_vm.$t('Categories.nameEn'),"imgName":'categories.svg'},on:{"changeValue":function($event){_vm.category.categoryNameEn = $event;
            _vm.$v.category.categoryNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-categoryNameUnd"),"value":_vm.category.categoryNameUnd,"title":_vm.$t('Categories.nameUnd'),"imgName":'categories.svg'},on:{"changeValue":function($event){_vm.category.categoryNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-categoryDescriptionAr"),"errors":_vm.errors_categoryDescriptionAr,"value":_vm.category.categoryDescriptionAr,"title":_vm.$t('Categories.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.category.categoryDescriptionAr = $event;
            _vm.$v.category.categoryDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-categoryDescriptionEn"),"errors":_vm.errors_categoryDescriptionEn,"value":_vm.category.categoryDescriptionEn,"title":_vm.$t('Categories.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.category.categoryDescriptionEn = $event;
            _vm.$v.category.categoryDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-categoryDescriptionUnd"),"value":_vm.category.categoryDescriptionUnd,"title":_vm.$t('Categories.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.category.categoryDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-categoryNotes"),"value":_vm.category.categoryNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.category.categoryNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }