<template>
  <div class="row custom-cards">
    <CustomCard
      v-for="(category, index) in categoriesData"
      :key="index"
      :className="'col-sm-6 col-md-4 col-lg-3'"
      :title="category.categoryNameCurrent"
      :description="category.fullCode"
      :imagePath="category.categoryImagePath"
      :defaultImg="defaultImg"
      :index="++index + filterData.currentIndex"
    >
      <li>
        <button
          :title="$t('info')"
          @click="
            setCategoryData(category);
            openBottomSheet('CategoryInfo');
          "
        >
          <img src="@/assets/images/info.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setCategoryData(category);
            openBottomSheet('CategoryQRCode');
          "
          :title="$t('general.qrCode')"
        >
          <img src="@/assets/images/qr-code.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasCategoryEdit())">
        <button
          @click="
            setCategoryData(category);
            openBottomSheet('CategoryUpdate');
          "
          :title="$t('edit')"
        >
          <img src="@/assets/images/pencil.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasCategoryFinaleDelete())">
        <button
          v-b-modal.CategoryDelete
          :title="$t('delete')"
          @click="setCategoryData(category)"
        >
          <img src="@/assets/images/trash.svg" />
        </button>
      </li>
      <li v-if="checkPrivilege(hasCategoryChangeActivationType())">
        <button
          v-b-modal.CategoryChangeActivationType
          :title="$t('changeActivationType')"
          @click="setCategoryData(category)"
        >
          <img src="@/assets/images/changeActivationType.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setCategoryData(category);
            openBottomSheet('ActionsData');
          "
          :title="$t('actionsData.modelName')"
        >
          <img src="@/assets/images/actions-data.svg" />
        </button>
      </li>
    </CustomCard>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../../utils/functions";
import {
  hasCategoryEdit,
  hasCategoryFinaleDelete,
  hasCategoryChangeActivationType,
} from "./../../../../../utils/privilegeHelper";
import CustomCard from "./../../../../../components/general/CustomCard.vue";
import generalMixin from "./../../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    CustomCard,
  },
  props: ["categoriesData", "filterData", "defaultImg"],
  methods: {
    setCategoryData(category) {
      this.$emit("setCategoryData", category);
    },
    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
    hasCategoryEdit,
    hasCategoryFinaleDelete,
    hasCategoryChangeActivationType,
  },
};
</script>
